import { ColorTheme } from 'constants/dark-mode'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'

const useIsDarkModeEnabledFromCookies = () => {
  const cookies = useCookie()

  return cookies.get(cookiesDataByName.color_theme) === ColorTheme.Dark
}

export default useIsDarkModeEnabledFromCookies
